<template>

  <div class="white p-3"
       id="sideNav"
       style="position: fixed; height: 100vh; width: 300px; border-radius: 0px 20px 20px 0px; z-index: 999">
    <div class="clearfi mb-5">
      <i @click="toggleNavigation"
         class="fa-solid fa-xmark float-end font-30 pe-1 blue-grey-text text-darken-2 cursor-pointer"></i>
    </div>
  </div>

  <div v-if="loading"
       class="position-fixed blue-grey blue-grey-text text-darken-2 lighten-5"
       style="width: 100vw; height: 100vh">
    <div class="vertical-align-parent w-100" style="height: 100vh">
      <div class="vertical-align-element">
        <div class="m-auto" style="width: fit-content">
          <JellyTriangle/>
        </div>
      </div>
    </div>
  </div>

  <div class="blue-grey blue-grey-text text-darken-2 lighten-5"
       v-if="auth !== null"
       style="min-height: 100vh">

    <!-- Top Bar -->
    <div class="clearfix w-100 p-3">
      <div class="float-start ps-2" style="line-height: 45px"></div>
      <div class="float-end">
        <UserCard
            v-on:logout="logout"
            :auth="auth"/>
      </div>
    </div>

    <!-- Pantalla -->
    <div class="d-flex">
      <!-- Contenido -->
      <div class="w-100 pb-3 pe-3 ps-3">
        <div class="h-100 container ps-0 pe-0">
          <router-view/>
        </div>
      </div>
    </div>
  </div>

  <div class="blue-grey blue-grey-text text-darken-2 lighten-5" style="min-height: 100vh" v-else>
    <div class="vertical-align-parent w-100" style="height: 100vh">
      <div class="vertical-align-element">
        <div class="w-50 p-4 m-auto border-radius-15"
             style="background-color: rgba(255, 255, 255, 0.7); text-align: left">
          <LoginComponent
              v-on:auth="getAuth"
              v-on:logout="logout"/>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import LoginComponent from "@/components/Auth/LoginComponent";
import config from "@/config";
import axios from 'axios';
import UserCard from "@/components/Auth/UserCard";
import JellyTriangle from "@/components/Loaders/JellyTriangle";
import {toggleNav} from "@/functions";
import {Modal} from 'bootstrap';

export default {

  name: 'App',

  components: {JellyTriangle, UserCard, LoginComponent},

  data() {
    return {
      auth: null,
      loading: false
    }
  },

  methods: {
    getAuth() {
      if (localStorage.token !== undefined) {
        this.loading = true;

        axios.post(config.API_URL + '/authenticate', {token: localStorage.token}).then(resp => {
          this.auth = resp.data;
          this.loading = false;
        }).catch(err => {
          console.log(err);
          this.logout();
          let modal = Modal(document.getElementById('myModal'), {});
          modal.hide();
        });
      }
    },
    logout() {
      localStorage.removeItem("token");
      this.auth = null;
    },
    toggleNavigation() {
      toggleNav();
    }
  },

  mounted() {
    this.getAuth();
  }

}

</script>

<style lang="scss" rel="stylesheet/scss"></style>