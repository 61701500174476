<template>

    <div>
        <DocumentScannerDemo />
    </div>

</template>

<script>

    import DocumentScannerDemo from "@/components/Scanner/DocumentScannerDemo";
    export default {
        components: {DocumentScannerDemo},
        props: [],

        name: 'HomeView',


        data() {
            return {}
        },

        watch: {},

        methods: {},

        mounted() {},

        created() {}

    }

</script>
