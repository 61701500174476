<template>

  <NativeModalComponent title="Logs">
    <template #button>
      <button @click="getLogs" class="btn btn-sm white font-800 w-100">
        <i class="fa-solid fa-list-ul me-1"></i> Logs
      </button>
    </template>

    <div>
      <div v-for="(e, index) in errors" :key="index">
        {{ e }}
        <hr/>
      </div>
    </div>
  </NativeModalComponent>

</template>

<script>


import NativeModalComponent from "@/components/NativeModalComponent.vue";
import {getErrorLogsFromLocalStorage} from "@/functions";

export default {
  components: {NativeModalComponent},

  name: 'LogModal',

  data() {
    return {
      errors: []
    }
  },

  methods: {
    getLogs() {
      this.errors = getErrorLogsFromLocalStorage().reverse()
    }
  }
}


</script>
