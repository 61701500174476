<template>
  <div>
    <div class="mb-3">
      <select v-model="hotel" class="custom-input me-3 white font-600"
              style="width: 170px; height: 28px; border-radius: 15px">
        <option v-for="h in hoteles" :value="h.ent_id" :key="h.ent_id">
          {{ h.ent_nom }}
        </option>
      </select>

      <button @click="type = 'id'"
              v-bind:class="{'grey darken-4 white-text': type === 'id'}"
              class="btn btn-sm white font-600 me-2">
        Documento Identidad
      </button>

      <button @click="type = 'passport'"
              v-bind:class="{'grey darken-4 white-text': type === 'passport'}"
              class="btn btn-sm white font-600">
        Pasaporte
      </button>
    </div>

    <div class="mb-3 d-flex">
      <WebCam class="w-75 flex-shrink-0 flex-grow-0"
              v-on:foto="takePhoto($event)"/>

      <!-- ID -->
      <div class="ms-3" v-if="type === 'id' && images[0] !== null">
        <div style="border-radius: 15px; overflow: hidden; min-height: 50px" class="position-relative mb-3">
          <img :src="images[0]">

          <div class="position-absolute" style="top: 10px; left: 12px">
                        <span class="pill grey lighten-4 grey-text text-darken-4">
                            <i v-if="this.index_photo === 0"
                               class="fa-regular fa-circle-dot fa-fade green-text me-1"></i>
                            Foto Frontal
                        </span>
          </div>
        </div>

        <div style="border-radius: 15px; overflow: hidden; min-height: 50px" class="position-relative">
          <img :src="images[1]">

          <div class="position-absolute" style="top: 10px; left: 12px">
                        <span class="pill grey lighten-4 grey-text text-darken-4">
                            <i v-if="this.index_photo === 1" class="fa-regular fa-circle-dot green-text me-1"></i>
                            Foto Trasera
                        </span>
          </div>
        </div>
      </div>

      <!-- Passport -->
      <div class="ms-3" v-else-if="type === 'passport' && images[0] !== null">
        <div style="border-radius: 15px; overflow: hidden; min-height: 50px" class="position-relative mb-3">
          <img :src="images[0]">

          <div class="position-absolute" style="top: 10px; left: 12px">
                        <span class="pill grey lighten-4 grey-text text-darken-4">
                            <i v-if="this.index_photo === 0" class="fa-regular fa-circle-dot green-text me-1"></i>
                            Foto
                        </span>
          </div>
        </div>
      </div>

      <!-- Instrucciones -->
      <div v-else class="ms-3">
        <section class="white border-radius-15 p-3">
          <section class="mb-3">
            <span class="font-800">1.</span> Asegurese que el hotel esta seleccionado
          </section>
          <section class="mb-3">
            <span class="font-800">2.</span> Elija el tipo de documento que desea escanear, ID o Pasaporte
          </section>
          <section class="mb-3">
            <span class="font-800">3.</span> Haga foto de las caras del documento que se indican
          </section>
          <section class="mb-3">
            <span class="font-800">4.</span> Enviar, automaticamente se cargará en GuestPro
          </section>
          <section class="mb-3">
            <div class="font-600 font-16">DNI/ID</div>
            <div>
              Centrar el documento y escanear ambas caras del documento de forma que el texto este recto en la imagen y
              sea leible todo el documento
            </div>
          </section>
          <section>
            <div class="font-600 font-16">Pasaporte</div>
            <div>
              Centrar el documento y escanear, asegurese que todo el documento sea visible para que se pueda extraer
              toda la inforamción
            </div>
          </section>
        </section>
      </div>
    </div>

    <hr/>

    <div class="mb-3">
      <button v-if="status === 0"
              @click="sentOcr"
              class="btn btn-sm grey darken-4 white-text font-700">
        <i class="fa-solid fa-id-card me-1"></i> Enviar
      </button>

      <button v-else-if="status === 1"
              disabled
              class="btn btn-sm grey lighten-4 grey-text text-darken-2 font-700">
        <i class="fa-solid fa-spinner fa-spin me-1"></i> Procesando
      </button>

      <button v-if="status === 2"
              @click="sentOcr"
              class="btn btn-sm green lighten-4 green-text text-darken-2 font-700">
        <i class="fa-solid fa-id-card me-1"></i> Enviado!
      </button>

      <button v-else-if="status === 3"
              @click="sentOcr"
              class="btn btn-sm red lighten-4 red-text text-darken-2 font-700">
        <i class="fa-solid fa-id-card me-1"></i> Error!
      </button>

      <button @click="restore" class="btn btn-sm white grey-text text-darken-4 font-weight-bolder ms-1">
        <i class="fa-solid fa-arrow-rotate-left"></i>
      </button>
    </div>

    <div class="red-text text-darken-2 font-600" v-if="errorMessage !== null">
      {{ errorMessage }}
    </div>

  </div>
</template>

<script>
import {saveErrorToLocalStorage} from "@/functions";

const headers = {
  'Content-Type': 'multipart/form-data',
  'Authorization': `Bearer ${process.env.VUE_APP_API_TOKEN}` // Mejorable con un env
};

import WebCam from "./WebCam";
import axios from 'axios';

export default {
  props: [],

  components: {WebCam},

  name: 'DocumentScannerDemo',

  data() {
    return {
      type: 'id',
      images: [null, null],
      index_photo: 0,
      data: null,
      status: 0,
      hoteles: [],
      auth: null,
      hotel: null,
      errorMessage: ''
    }
  },

  methods: {
    restore() {
      this.images = [null, null];
      this.index_photo = 0;
    },
    takePhoto(photo) {
      if (this.type === 'id') {
        this.images[this.index_photo] = photo;

        if (this.index_photo === 1) {
          this.index_photo = 0;
        } else {
          this.index_photo = 1;
        }
      } else {
        this.images[this.index_photo] = photo;
      }
      this.$forceUpdate();
    },
    sentOcr() {
      try {
        let formData = new FormData();

        if (this.type === 'id') {
          if (this.images[0] === null || this.images[1] === null) {
            return;
          }
        } else {
          if (this.images[0] === null) {
            return;
          }
        }

        this.status = 1;

        formData.append('type', this.type);
        if (this.images[0] !== null) {
          formData.append('image_frontal', this.DataURIToBlob(this.images[0]));
        }
        if (this.images[1] !== null) {
          formData.append('image_trasera', this.DataURIToBlob(this.images[1]));
        }

        formData.append('hotel_id', this.hotel);


        axios.post(`${process.env.VUE_APP_API_URL}/document-ocr/scan`, formData, {headers: headers}).then(resp => {
          this.data = resp.data;

          if (this.data.code === 200) {
            this.status = 2;

            this.images = [null, null];
          } else {
            this.status = 3;
            this.errorMessage = this.data.data;
            saveErrorToLocalStorage(this.data.data);
          }
        }).catch(err => {
          this.status = 3;
          saveErrorToLocalStorage( err );
        });
      }catch ( err ){
        saveErrorToLocalStorage( err );
      }
    },
    DataURIToBlob(dataURI) {
      const splitDataURI = dataURI.split(',')
      const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
      const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

      const ia = new Uint8Array(byteString.length)
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

      return new Blob([ia], {type: mimeString})
    },
    getHoteles() {
      axios.post(process.env.VUE_APP_API_URL + '/auth/hoteles/get', {}, {
        headers: {Authorization: `Bearer ${localStorage.token}`}
      }).then(resp => {
        this.hoteles = resp.data;

        if (this.hoteles.length > 0) {
          this.hotel = this.hoteles[0].ent_id;
        }
      }).catch(err => {
        console.log(err)
      });
    }
  },

  created() {
    this.getHoteles();
  }
}
</script>

<style scoped>
img {
  width: 100%;
}
</style>